<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddReader"
      id="show-modal-add-reader"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddReader"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ idSelected ? "Edit Reader" : "Add Reader" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isNameRequire }"
            >
              <label class="fw-bold" for=""
                >Name
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="reader.name"
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                :disabled="idSelected"
              />
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isNameRequire"
                >The field is required.</span
              >
            </div>
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isRegistrationCodeRequire }"
            >
              <label class="fw-bold" for=""
                >Registration code
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="reader.registration_code"
                type="text"
                id="registration_code"
                name="registration_code"
                placeholder="Enter registration code"
                :disabled="idSelected"
              />
            </div>
            <div class="w-100 mb-3">
              <span
                class="is-invalid"
                v-if="fieldRequired.isRegistrationCodeRequire"
                >The field is required.</span
              >
            </div>
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isLocationRequire }"
            >
              <label class="fw-bold" for="">Virtual Branch </label>
              <Multiselect
                :searchable="true"
                v-model="reader.virtual_branch_id"
                :options="virtualBranches"
                placeholder="Select virtual branch"
              ></Multiselect>
            </div>
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isLocationRequire }"
            >
              <label class="fw-bold" for=""
                >Location
                <span class="text-danger">*</span>
              </label>
              <Multiselect
                v-model="reader.location"
                :options="locations"
                placeholder="Select location"
                :canClear="false"
                :canDeselect="false"
                :disabled="idSelected"
              ></Multiselect>
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isLocationRequire"
                >The field is required.</span
              >
            </div>
            <div class="d-flex justify-content-end mt-5">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveReader()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background btn-close-modal-add-reader"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ReaderService from "../services/reader.service";
import Multiselect from "@vueform/multiselect";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

export default {
  name: "create-reader",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
    Multiselect,
    MaterialSnackbar,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    idSelected: {
      type: Number,
      default: 0,
    },
    physicalBranchId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      fieldRequired: {},
      loaderActive: false,
      reader: {
        id: 0,
        name: "",
        registration_code: "",
        location: "",
        physical_branch_id: this.physicalBranchId,
        virtual_branch_id: 0,
      },
      locations: [],
      virtualBranches: [],
    };
  },
  methods: {
    getReaderDetail() {
      if (this.idSelected) {
        this.showLoader();
        ReaderService.detail(this.idSelected).then(
          (response) => {
            if (response.data.result.isSuccess) {
              const reader = response.data.data;

              this.reader = {
                id: reader.id,
                name: reader.name,
                registration_code: reader.registration_code,
                location: reader.location,
                physical_branch_id: this.physicalBranchId,
                virtual_branch_id: reader.virtual_branch_id,
              };
              // this.snackbar = "success";
              // this.message = "Edit Reader Success";
              // this.setTimeOutNotification();
              // $('.btn-close-modal').click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveReader() {
      if (this.processValidate()) {
        this.showLoader();
        ReaderService.createOrUpdate(this.reader).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message =
                (this.idSelected ? "Edit" : "Add") + " Reader Success";
              this.setTimeOutNotification();
              this.$emit("reload-data");
              $(".btn-close-modal-add-reader").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    getDataFilter() {
      this.showLoader();
      ReaderService.getDataFilter({
        physical_branch_id: this.physicalBranchId,
        locations: 1,
        virtual_branches: 1,
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.locations = response.data.data.locations;
            this.virtualBranches = response.data.data.virtual_branches;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    resetRequired() {
      this.fieldRequired.isNameRequire = false;
      this.fieldRequired.isRegistrationCodeRequire = false;
      this.fieldRequired.isLocationRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;

      if (this.reader.name == "" || this.reader.name == null) {
        this.fieldRequired.isNameRequire = true;
        isValid = false;
      }

      if (
        this.reader.registration_code == "" ||
        this.reader.registration_code == null
      ) {
        this.fieldRequired.isRegistrationCodeRequire = true;
        isValid = false;
      }

      if (this.reader.location == "" || this.reader.location == null) {
        this.fieldRequired.isLocationRequire = true;
        isValid = false;
      }

      return isValid;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.resetRequired();
      this.reader = {
        id: 0,
        name: "",
        registration_code: "",
        location: "",
        physical_branch_id: this.physicalBranchId,
        virtual_branch_id: 0,
      };

      this.getDataFilter();

      if (this.idSelected) {
        this.getReaderDetail();
      }
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}
</style>
