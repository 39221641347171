<template>
  <div>
    <div class="mb-2 pe-4">
      <div class="d-flex align-items-center">
        <div class="color-common p-2 d-flex justify-content-between w-100">
          <h3 class="title-page mb-0 ps-3">WA Marketing Template</h3>
          <material-button class="btn-background btn-sm" @click="createTemplate()">
            Add template
          </material-button>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex mt-4 s-branch align-items-center">
            </div>
            <div class="mt-5">
              <div class="row mt-5">
                <div class="px-0 mt-4">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder">No.</th>
                          <th class="text-center font-weight-bolder">
                            Template Name
                          </th>
                          <th class="text-center font-weight-bolder">
                            Category
                          </th>
                          <th class="text-center font-weight-bolder">
                            Template
                          </th>
                          <th class="text-center font-weight-bolder w-5">
                            Language
                          </th>
                          <th class="text-center font-weight-bolder">
                            Status
                          </th>
                          <th class="text-center font-weight-bolder">
                            Last updated (UTC-00)
                          </th>
                          <th class="text-center font-weight-bolder">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="venue.length">
                        <tr v-for="(item, index) in venue" :key="index">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-center">{{ item.venue_id_text }}</td>
                          <td class="text-center">{{ item.name }}</td>
                          <td class="text-center">
                            <img
                              :src="item.image"
                              class="border-radius-lg height-voucher"
                            />
                          </td>
                          <td class="text-center">{{ item.type_text }}</td>
                          <td class="text-center">
                            <div>Minimum: {{ item.min_advance_time_text }}</div>
                            <div>Maximum: {{ item.max_advance_time_text }}</div>
                          </td>
                          <td class="text-start">
                          </td>
                          <td class="text-center">
                            <a
                              class="btn btn-link text-dark px-2 mb-0"
                              @click="addVenue(item.id, 'edit')"
                            >Duplicate
                            </a>
                            <a
                              class="btn btn-link text-danger text-gradient px-2 mb-0"
                              @click="editBookingSlot(item.id)"
                            >
                              <i
                                class="far fa-trash-alt text-dark me-2"
                                aria-hidden="true"
                              ></i
                              >Delete
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else class="no-data">
                        <tr>
                          <td colspan="10" class="text-center">
                            Empty, no template.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination
                  v-if="pagination.last_page > 1"
                  :pagination="pagination"
                  :current_page="pagination.current_page"
                  :last_page="pagination.last_page"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <AddVenue
    :keyReload="keyReloadAddVenue"
    :isEditVenue="isEditVenue"
    :venueId="venueIdSelect"
    @reload-list-venue="reloadListVenue"
  ></AddVenue>
  <material-loading :active="loaderActive" />
  <ModalConfirm :message="messageConfirm" :process-yes="processYes" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import BookingService from "../../../services/booking.service";
import TestService from "../../../services/test.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import AddVenue from "@/components/AddVenue.vue";
import Pagination from "@/components/Pagination.vue";
import $ from "jquery";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    AddVenue,
    Pagination,
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      branches: [],
      branchIdSelect: this.$route.query.branch_id ?? 0,
      messageConfirm: "",
      venueIdSelect: 0,
      keyReloadAddVenue: "keyReloadAddVenue",
      isEditVenue: false,
      venue: [],
      pagination: {},
      page: 1,
    };
  },
  async created() {
    await this.getBranches();
    this.getList(this.page);
  },
  methods: {
    confirmChangeStatus(voucherId, status) {
      if (status) {
        this.messageConfirm = "Are you sure you want to de-activate ?";
      } else {
        this.messageConfirm = "Are you sure you want to activate ?";
      }
      this.voucherId = voucherId;
      $(".btn-confirm-modal").click();
    },

    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      BookingService.changeStatusVenue(this.voucherId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.venue.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            if (response.data.data.status) {
              this.message = "Service is activated";
            } else {
              this.message = "Service is deactivated";
            }
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getBranches() {
      this.showLoader();
      await TestService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branches = response.data.data;
            this.branches.unshift({
              value: 0,
              label: '-- All Branches --'
            });
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    reloadListVenue(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getList(this.page);
    },

    async selectBranch(branchId) {
      this.branchIdSelect = branchId;
      this.showLoader();
      await this.getList(this.page);
      this.hideLoader();
    },

    async getList(page) {
      var dataForm = {
        branch_id: this.branchIdSelect,
        page: page,
      };

      await BookingService.listVenue(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.venue = [];
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    createTemplate() {
      this.$router.push("/marketing/wa-marketing-template");
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.nav-first {
  position: relative;
}
.nav-link {
  font-weight: bold;
}

.nav-first .count-order {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid red;
  background: red;
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 7px;
}

.multiselect {
  margin: 0 !important;
  max-width: 400px !important;
}

#general-tab .nav-item {
  width: 25%;
  text-align: center;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 100%;
  max-width: 500px;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.cl-22 {
  color: #222 !important;
}

.show-order-info {
  font-size: 28px !important;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
.btn-confirm-order {
  min-width: 50px !important;
  padding: 5px 10px !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-confirm-order:hover {
  background: #28a745 !important;
  color: #ffffff !important;
}

.timeslot-tag {
  width: fit-content;
  padding: 1px 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 4px;
}
</style>
