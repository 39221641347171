import api from './api';

class SettingTemplateService {
  getDataTemplates(dataForm) {
    return api.post('/merchant/templates', dataForm);
  }

  getDataTemplateDetail(templateId) {
    if (templateId) {
      return api.post('/merchant/template/' + templateId);
    } else {
      return api.post('/merchant/template');
    }
  }

  addOrUpdateTemplate(dataForm) {
    return api.post('/merchant/template-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  createGroupAudience(dataForm) {
    return api.post('/merchant/template-create-group-audience', dataForm);
  }

  getDataTargetAudience() {
    return api.post('/merchant/target-audience');
  }

  deleteTemplate(templateId) {
    return api.post('/merchant/template/delete/' + templateId);
  }
}

export default new SettingTemplateService();
