<template>
  <div class="input-group" :class="`input-group-${variant} ${getStatus(error, success)}`">
    <textarea
      ref="textarea"
      :id="id"
      class="form-control"
      :rows="rows"
      :name="name"
      :value="modelValue"
      @input="handleInput"
      @paste="handlePaste"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="disabled"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "MaterialTextarea",
  props: {
    modelValue: { type: String, required: true },
    variant: { type: String, default: "outline" },
    id: { type: String, required: true },
    name: { type: String, default: "" },
    placeholder: { type: String, default: "Your text here..." },
    isRequired: Boolean,
    disabled: { type: Boolean, default: false },
    rows: { type: Number, default: 5 },
    success: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
  },
  data() {
    return {
      forbiddenTags: ["<MEMBER NAME>", "<BRAND>", "<OUTLET>"]
    };
  },
  methods: {
    getStatus(error, success) {
      return success ? "is-valid" : error ? "is-invalid" : null;
    },

    insertAtCursor(tag) {
      const textarea = this.$refs.textarea;
      if (!textarea) return;

      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const currentValue = this.modelValue;

      // Kiểm tra nếu tag đã tồn tại
      if (currentValue.includes(`<${tag}>`)) {
        this.$emit("error", `Tag <${tag}> đã tồn tại!`);
        return;
      }

      const newValue =
        currentValue.substring(0, startPos) + `<${tag}>` + currentValue.substring(endPos);
      this.$emit("update:modelValue", newValue);

      this.$nextTick(() => {
        textarea.focus();
        textarea.selectionStart = textarea.selectionEnd = startPos + tag.length + 2;
      });
    },

    handleInput(event) {
      let value = event.target.value;

      this.forbiddenTags.forEach(tag => {
        let matches = value.match(new RegExp(tag, "g")) || [];

        // Nếu có hơn 1 lần xuất hiện, chỉ xóa lần cuối cùng
        if (matches.length > 1) {
          this.$emit("error", `Duplicate entry detected: ${tag}`);

          // Tìm vị trí xuất hiện cuối cùng của tag trong chuỗi
          let lastIndex = value.lastIndexOf(tag);
          if (lastIndex !== -1) {
            value = value.substring(0, lastIndex) + value.substring(lastIndex + tag.length);
          }
        }
      });

      this.$emit("update:modelValue", value);
    },

    handlePaste(event) {
      let clipboardData = event.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData("Text");

      // Kiểm tra nếu có tag cấm trong nội dung dán vào
      if (this.forbiddenTags.some(tag => pastedData.includes(tag))) {
        event.preventDefault();
        this.$emit("error", "Each tag can only appear once!");
      }
    }
  }
};
</script>
