import api from './api';

class ReaderService {
  getList(dataForm) {
    return api.post('/merchant/readers', dataForm);
  }

  detail(readerId) {
    return api.get('/merchant/readers/detail/' + readerId);
  }

  createOrUpdate(dataForm) {
    return api.post('/merchant/readers/create-or-update', dataForm);
  }

  getDataFilter(dataForm) {
    return api.post('/merchant/readers/get-data-filter', dataForm);
  }

  delete(readerId) {
    return api.post('/merchant/readers/delete/' + readerId);
  }

  changeStatus(readerId) {
    return api.post('/merchant/readers/change-status/' + readerId);
  }
}

export default new ReaderService();
