<template>
  <div>
    <div class="row mb-3 pe-4">
      <div class="col-lg-8 col-md-6 col-6 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-2">KIOSK GENERAL SETTING</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6 d-flex justify-content-end">
        <material-button
          @click="updateKioskSettings()"
          class="btn-background btn-sm"
        >
          {{ $t("Save") }}
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <div class="c-container">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-12 mx-auto pb-5 mt-2"
            v-if="isLoadData"
          >
            <div class="card-body" style="padding: 0rem 0rem">
              <div class="row d-flex">
                <div class="tab-list p-0">
                  <ul id="general-tab" class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        id="nav_item_1"
                        class="nav-link active"
                        @click="changeTab(1)"
                      >
                        {{ $t("Options") }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_2" class="nav-link" @click="changeTab(2)">
                        Payment Mode
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="row card-custom-new mt-3">
                  <div class="col-12" v-if="isOptions">
                    <div class="row">
                      <div
                        class="col-lg-4 col-12 label-form d-flex align-items-center"
                      >
                        Virtual Branch
                      </div>
                      <div
                        class="col-lg-8 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <Multiselect
                          :searchable="true"
                          v-model="form.branch_id"
                          :options="branchs"
                          :canClear="false"
                          @change="changeBranch"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-lg-4 col-12 label-form d-flex align-items-center"
                      >
                        Operation Mode
                      </div>
                      <div
                        class="col-lg-8 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <Multiselect
                          :searchable="true"
                          v-model="form.operation_mode"
                          :options="operation_modes"
                          :canClear="false"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-lg-4 col-12 label-form d-flex align-items-center"
                      >
                        Enable Dine-in/Take Away Option
                      </div>
                      <div
                        class="col-lg-8 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <div
                          :class="{ active: isActiveDiningOption }"
                          class="toggle_container"
                        >
                          <MaterialToggleButton
                            labelEnableText=""
                            labelDisableText=""
                            id="event_handle_dining_option"
                            v-on:change="triggerEventDiningOption"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3" v-if="isActiveDiningOption">
                      <div
                        class="col-lg-4 col-12 label-form d-flex align-items-center"
                      >
                        Dining Location
                      </div>
                      <div
                        class="col-lg-8 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <Multiselect
                          :searchable="true"
                          v-model="form.dining_location"
                          :options="dining_locations"
                          :canClear="false"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-lg-4 col-12 label-form d-flex align-items-center"
                      >
                        Show Buzzer Number
                      </div>
                      <div
                        class="col-lg-8 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <div
                          :class="{ active: isActiveBuzzerNumber }"
                          class="toggle_container"
                        >
                          <MaterialToggleButton
                            labelEnableText=""
                            labelDisableText=""
                            id="event_handle_buzzer_number"
                            v-on:change="triggerEventBuzzerNumber"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12" v-if="isPaymentMode">
                    <div class="row">
                      <div
                        class="col-lg-2 col-12 label-form d-flex align-items-center"
                      >
                        Virtual Branch
                      </div>
                      <div
                        class="col-lg-10 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <Multiselect
                          :searchable="true"
                          v-model="form.branch_id"
                          :options="branchs"
                          :canClear="false"
                          @change="changeBranch"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-6 col-12">
                        <div class="row">
                          <div
                            class="col-lg-4 col-12 label-form d-flex align-items-center"
                          >
                            Terminal Type
                          </div>
                          <div
                            class="col-lg-8 col-12 justify-content-start"
                            style="align-items: center; gap: 30px"
                          >
                            <Multiselect
                              :searchable="true"
                              v-model="form.terminal_type"
                              :options="terminal_types"
                              :canClear="false"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12">
                        <div class="row">
                          <div
                            class="col-lg-4 col-12 label-form d-flex align-items-center"
                          >
                            Terminal ID
                          </div>
                          <div
                            class="col-lg-8 col-12"
                            style="align-items: center; gap: 30px"
                          >
                            <material-input
                              v-model="form.terminal_id"
                              extraClass="ml-5"
                              placeholder="Enter terminal id"
                              type="text"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-lg-2 col-12 label-form d-flex align-items-center"
                      >
                        Payment Mode
                      </div>
                      <div
                        class="col-lg-10 col-12"
                        style="align-items: center; gap: 30px"
                      >
                        <div class="d-flex align-items-center label-form">
                          <material-checkbox
                            @change="changeAllowPayNow(form.allow_paynow)"
                            v-model="form.allow_paynow"
                            :checked="form.allow_paynow"
                            name="paynow"
                            class="align-items-center px-3"
                          ></material-checkbox>
                          PayNow with Stripe
                        </div>
                        <div class="d-flex align-items-center label-form">
                          <material-checkbox
                            @change="
                              changeAllowCreditCard(form.allow_creditcard)
                            "
                            v-model="form.allow_creditcard"
                            :checked="form.allow_creditcard"
                            name="creditcard"
                            class="align-items-center px-3"
                          ></material-checkbox>
                          Credit Card
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import $ from "jquery";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import TestService from "../../../services/test.service";
import SettingStoreService from "../../../services/settingStore.service";
import SettingGeneralService from "../../../services/settingGeneral.service";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";

export default {
  name: "KioskGeneral",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Multiselect,
    MaterialInput,
    MaterialCheckbox,
    MaterialToggleButton,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      isLoadData: true,
      type: 1,
      isOptions: false,
      isPaymentMode: false,
      form: {
        branch_id: 0,
        terminal_type: 1,
        terminal_id: "",
        allow_paynow: 0,
        allow_creditcard: 0,
        operation_mode: 1,
        dining_option: 0,
        buzzer_number: 0,
        dining_location: 1,
      },
      branchs: [],
      isActiveDiningOption: false,
      isActiveBuzzerNumber: false,
      operation_modes: [
        { value: 1, label: "Test" },
        { value: 2, label: "Live" },
      ],
      dining_locations: [
        { value: 0, label: "Both" },
        { value: 1, label: "Takeaway" },
        { value: 2, label: "Dine In" },
      ],
      terminal_types: [{ value: 1, label: "Stripe" }],
    };
  },
  created() {
    this.changeTab(this.type);
    this.getBranches();
  },
  mounted() {},
  methods: {
    resetTabs() {
      this.isOptions = false;
      this.isPaymentMode = false;
    },

    changeTab(type) {
      this.resetTabs();
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      if (type == 1) {
        this.isOptions = true;
      } else if (type == 2) {
        this.isPaymentMode = true;
      }
    },

    changeBranch(id) {
      this.form.branch_id = id;
      this.showLoader();
      SettingStoreService.getDataBranchDetail(id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const branch = response.data.data.branch;

            this.form.terminal_type = branch.terminal_type;
            this.form.terminal_id = branch.terminal_id;
            this.form.allow_paynow = branch.allow_paynow;
            this.form.allow_creditcard = branch.allow_creditcard;
            this.form.operation_mode = branch.operation_mode;
            this.form.dining_option = branch.show_dining_option;
            this.isActiveDiningOption = branch.show_dining_option == 1 ? true : false;
            this.form.buzzer_number = branch.show_buzzer_number;
            this.isActiveBuzzerNumber = branch.show_buzzer_number == 1 ? true : false;
            this.form.dining_location = branch.dining_location;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getBranches() {
      this.isLoadData = false;
      this.showLoader();
      TestService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branchs = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    updateKioskSettings() {
      this.showLoader();
      SettingGeneralService.updateKioskSettings(this.form).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Save success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    triggerEventDiningOption(value) {
      if (typeof value == "boolean") {
        this.isActiveDiningOption = !this.isActiveDiningOption;
        this.form.dining_option = this.isActiveDiningOption ? 1 : 0;
      }
    },

    triggerEventBuzzerNumber(value) {
      if (typeof value == "boolean") {
        this.isActiveBuzzerNumber = !this.isActiveBuzzerNumber;
        this.form.buzzer_number = this.isActiveBuzzerNumber ? 1 : 0;
      }
    },

    changeAllowPayNow(value = 0) {
      if (typeof value == "boolean") {
        this.form.allow_paynow = value ? 1 : 0;
      }
    },

    changeAllowCreditCard(value = 0) {
      if (typeof value == "boolean") {
        this.form.allow_creditcard = value ? 1 : 0;
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>

<style scoped>
.c-container {
  width: 100%;
  max-width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.tab-list {
  overflow-x: auto;
  padding-bottom: 5px !important;
}

.tab-list::-webkit-scrollbar {
  height: 7px;
}

.tab-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tab-list::-webkit-scrollbar-thumb {
  background: #888;
}

.tab-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tab-list #general-tab {
  flex-wrap: unset !important;
}

.tab-list #general-tab li.nav-item {
  min-width: fit-content;
}

#preview img {
  max-width: 80px;
  max-height: 80px;
}

@media only screen and (max-width: 600px) {
  .wrap-card .card-item:last-child {
    margin-bottom: 80px !important;
  }

  .toggle-switch-custom {
    padding-top: 5px;
  }
}

.multiselect {
  max-width: 500px !important;
  margin-left: 0;
}
</style>
