<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4 class="
            col-lg-10 col-md-10 col-12
            text-back
            d-flex
            align-items-center
            ps-2
          ">
          <i class="fa fa-chevron-circle-left pe-2" aria-hidden="true" @click="backTemplateList()"
            style="cursor: pointer"></i>
          <div @click="backTemplateList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container" v-if="isLoadDoneData">
        <div class="row pt-3">
          <div class="col-lg-12 col-md-12 col-12 mx-auto pb-5">
            <h3 class="title-page ps-2">
              {{ "NEW WA MARKETING TEMPLATE" }}
            </h3>
            <div class="table-responsive custom-table-noborder">
              <div class="
                  table-responsive
                  p-2
                  custom-table-noborder
                  col-lg-12 col-md-12 col-12
                ">
                <table class="table align-items-center mb-0 add-template-table">
                  <tbody>
                    <tr>
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Template Name</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-input v-model="form.template_name" id="template_name" name="template_name"
                          placeholder="Enter template name" />
                        <span class="is-invalid" v-if="isTemplateNameRequire">The field is required.</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Category</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="3">
                        <Multiselect v-model="form.category" :options="[{'value': 'MARKETING', 'label': 'Marketing'}]" :value="'MARKETING'"
                          style="width: 300px; display: inline-flex;" :canClear="false" />
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Language</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="3">
                        <Multiselect v-model="form.language" :options="langs" :value="''"
                          style="width: 300px; display: inline-flex;" :canClear="false" />
                      </td>
                    </tr>
                    <tr v-if="isLanguegeRequire">
                      <td class="align-middle" colspan="2">
                      </td>
                      <td colspan="3" class="pt-0">
                          <span class="is-invalid">The field is required.</span>
                      </td>
                      <td colspan="3">
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Message</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="4">
                        <span class="label-form" style="white-space: normal; width: 80%;">Please do take note that Meta is very serious about the quality of the content you send out.
                           Sending out low quality content where people have flag not interested or spam will likely put business account rating down and your daily traffic quota could be reduced dramatically.</span>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form"></div>
                        </div>
                      </td>
                      <td colspan="4">
                        <material-textarea ref="customTextarea" v-model="form.body_text" id="custom-textarea" @error="showError" />
                      </td>
                      <td colspan="2"> 
                        <div style="min-width: 170px;">
                          <button
                            v-if="showButtons.memberName"
                            @click="insertTag('MEMBER NAME')"
                            class="btn mb-1 btn-success btn-md btn-background btn-sm capitalize" style="min-width: 170px;">
                            MEMBER NAME
                          </button><br>
                          <button 
                            v-if="showButtons.brand"
                            @click="insertTag('BRAND')"
                            class="btn mb-1 btn-success btn-md btn-background btn-sm capitalize" style="min-width: 170px;">
                            BRAND
                          </button><br>
                          <button
                            v-if="showButtons.outlet"
                            @click="insertTag('OUTLET')"
                            class="btn mb-0 btn-success btn-md btn-background btn-sm capitalize" style="min-width: 170px;">
                            OUTLET
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                      </td>
                      <td colspan="3" class="pt-0">
                        <span class="is-invalid" v-if="isMessageRequire">The field is required.<br></span>
                        <span class="is-invalid" v-if="errorMessage">{{ errorMessage }}</span>
                      </td>
                      <td colspan="3">
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Variable sample</div>
                        </div>
                      </td>
                      <td colspan="4 ">
                        <div class="" style="background-color: #FFD18A; padding: 5px; border-radius: 5px;">
                          <div class="ps-0 label-form text-gray" style="white-space: normal; width: 100%;">
                            To get your content approved for use on WhatsApp, it's important to provide a sample of your content.
                            This will help us to review and determine if it follows our guidelines.
                              Please make sure that the content you provide is representative of what you plan to send on WhatsApp.
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form"></div>
                        </div>
                      </td>
                      <td colspan="4">
                        <table class="variable-table table table-bordered">
                            <tr>
                              <th class=" label-form">Variable</th>
                              <th class=" label-form">Sample content </th>
                            </tr>
                            <tr>
                              <td class="label-form">{{ "<MEMBER NAME>" }}</td>
                              <td class=""> <input class="form-control" v-model="form.member_name" name="member_name"
                                placeholder="Enter member name example" /></td>
                            </tr>
                            <tr>
                              <td class=" label-form">{{ "<BRAND>" }}</td>
                              <td class=""> <input class="form-control" v-model="form.brand" name="brand"
                                placeholder="Enter brand example" /></td>
                            </tr>
                            <tr>
                              <td class=" label-form">{{ "<OUTLET>" }}</td>
                              <td class=""> <input class="form-control" v-model="form.outlet" name="outlet"
                                placeholder="Enter outlet example" /></td>
                            </tr>
                        </table>
                        <span class="is-invalid" v-if="isMemberNameRequire">The member name is required.<br></span>
                        <span class="is-invalid" v-if="isBrandRequire">The brand is required.<br></span>
                        <span class="is-invalid" v-if="isOutletRequire">Outlet is required.</span>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="8">
                        <div class="d-flex justify-content-end">
                          <material-button class="mx-2 btn-background btn-sm"
                            @click="backTemplateList()">
                            Cancel
                          </material-button>
                          <material-button class="mx-2 btn-background btn-sm" @click="saveTemplate()">
                            Submit for Approval
                          </material-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
          :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
          :close-handler="closeSnackbar" />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
// import CustomEditor from '../../components/CustomEditor.vue';
import MaterialTextarea from "@/components/CustomMaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import SettingCampaignService from "../../../services/settingCampaign.service.js";
import SettingTemplateService from "../../../services/settingTemplate.service.js";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
// import $ from "jquery";
export default {
  name: "TemplateDetail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    Multiselect,
    MaterialTextarea,
    // CustomEditor,
  },
  data() {
    return {
      form: {
        id: null,
        template_name: '',
        category: 'MARKETING',
        language: '',
        body_text: '',
        member_name: '',
        brand: '',
        outlet: '',
      },

      loaderActive: false,
      isTemplateNameRequire: false,
      isLanguegeRequire: false,
      isMessageRequire: false,
      isMemberNameRequire: false,
      isBrandRequire: false,
      isOutletRequire: false,

      isLoadDoneData: false,
      snackbar: null,
      merchantCode: "",
      disableVoucherDropdown: false,
      disableMinimumSpendAmountInput: false,
      disableMinimumNumberOfItemPurchasedInput: false,
      disableNumberOfStoresVisitInput: false,
      langs: [
        { "value": "", "label": "Select language" },
        { "value": "en", "label": "English" },
        { "value": "zh_CN", "label": "Chinese (CHN)" }
      ],
      submittedText: "",
      showButtons: {
        memberName: true,
        brand: true,
        outlet: true,
      },
      errorMessage: "",
    };
  },
  created() {
    this.targetAudience = []
    this.branches = []
    this.getCampaignDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {

    insertTag(tag) {
      this.errorMessage = ""; // Xóa lỗi trước đó
      this.$refs.customTextarea.insertAtCursor(tag);
      this.showButtons[this.getKey(tag)] = false;
    },
    checkTags() {
      this.showButtons.memberName = !this.form.body_text.includes("<MEMBER NAME>");
      this.showButtons.brand = !this.form.body_text.includes("<BRAND>");
      this.showButtons.outlet = !this.form.body_text.includes("<OUTLET>");
    },
    getKey(tag) {
      return tag.toLowerCase().replace(" ", "");
    },
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    showError(message) {
      this.errorMessage = '';
      this.errorMessage = message;
    },

    getCampaignDetail() {
      var campaignId = this.$route.params.id ?? 0;
      this.isLoadDoneData = false;
      this.showLoader();
      SettingCampaignService.getDataCampaignDetail(campaignId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveTemplate() {
      let beforeSave = this.form.body_text;
     
      if (this.processValidate()) {

        if (this.form.body_text.includes("<MEMBER NAME>")) {
          this.form.body_text = this.form.body_text.replace(/<MEMBER NAME>/g, "{{1}}");
        }

        if (this.form.body_text.includes("<BRAND>")) {
          this.form.body_text = this.form.body_text.replace(/<BRAND>/g, "{{2}}");
        }

        if (this.form.body_text.includes("<OUTLET>")) {
          this.form.body_text = this.form.body_text.replace(/<OUTLET>/g, "{{3}}");
        }

        SettingTemplateService.addOrUpdateTemplate(this.form).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Template Success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }

      this.form.body_text = beforeSave;
    },
    dateTimeFormat(date, time) {
      if (date == null) {
        return null;
      } else {
        if (time == null) {
          return moment(date).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(date).format("YYYY-MM-DD") +
            " " +
            moment(time).format("H:m")
          );
        }
      }
    },
    backTemplateList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/marketing/wa-marketing-templates" + "?page=" + page);
      } else {
        this.$router.push("/marketing/wa-marketing-templates");
      }
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.form.template_name == "" || this.form.template_name == null) {
        this.isTemplateNameRequire = true;
        isValid = false;
      }
      if (this.form.language == "" || this.form.language == null) {
        this.isLanguegeRequire = true;
        isValid = false;
      }
      if (this.form.body_text == "" || this.form.body_text == null) {
        this.isMessageRequire = true;
        isValid = false;
      }
      if (this.form.body_text && this.form.body_text.includes("<MEMBER NAME>") && (this.form.member_name == "" || this.form.member_name == null)) {
        this.isMemberNameRequire = true;
        isValid = false;
      }
      if (this.form.body_text && this.form.body_text.includes("<BRAND>") && (this.form.brand == "" || this.form.brand == null)) {
        this.isBrandRequire = true;
        isValid = false;
      }
      if (this.form.body_text && this.form.body_text.includes("<OUTLET>") && (this.form.outlet == "" || this.form.outlet == null)) {
        this.isOutletRequire = true;
        isValid = false;
      }

      console.log('this.form.member_name', this.form.member_name == "");
      console.log('isBrandRequire', this.isBrandRequire);
      console.log('isOutletRequire', this.isOutletRequire);

      return isValid;
    },

    resetRequired() {
      this.isTemplateNameRequire = false;
      this.isLanguegeRequire = false;
      this.isMessageRequire = false;
      this.isMemberNameRequire = false;
      this.isBrandRequire = false;
      this.isOutletRequire = false;
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    "form.body_text": "checkTags",
  },
};
</script>
<style>
  .add-template-table .ck-editor__editable {
    min-height: 200px !important;
  }
</style>
<style scoped>
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  min-height: 200px !important;
}
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

div.div-custom {
  padding-top: 10px;
}

/* .background-color-custom {
  background-color: #EEEEEE;
} */
#preview img {
  min-width: 150px;
  max-height: 60px;
}

.variable-table {
  border: 1px solid #454444 !important;
  text-align: left;
  margin-bottom: 0 !important;
}

.variable-table th, td {
  border: 1px solid #454444 !important;
  text-align: left;
}

.variable-table th {
  padding: 10px;
  font-size: 17px !important;
}

.variable-table input {
  width: 100%;
  border: none;
  background-color: #E8F0FE;
}

</style>