<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4 class="
            col-lg-10 col-md-10 col-12
            text-back
            d-flex
            align-items-center
            ps-2
          ">
          <i class="fa fa-chevron-circle-left pe-2" aria-hidden="true" @click="backCampaignList()"
            style="cursor: pointer"></i>
          <div @click="backCampaignList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container" v-if="isLoadDoneData">
        <div class="row pt-3">
          <div class="col-lg-12 col-md-12 col-12 mx-auto pb-5">
            <h3 class="title-page ps-2">
              {{ "CREATE CAMPAIGN" }}
            </h3>
            <div class="table-responsive custom-table-noborder">
              <div class="
                  table-responsive
                  p-2
                  custom-table-noborder
                  col-lg-12 col-md-12 col-12
                ">
                <table class="table align-items-center mb-0">
                  <tbody>
                    <tr>
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Campaign ID</div>
                        </div>
                      </td>
                      <td colspan="6">
                        {{ form.campaign_id_text }}
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Campaign Title</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-input v-model="form.campaign_tile" id="campaign_tile" name="campaign_tile"
                          placeholder="Enter campaign title" />
                        <span class="is-invalid" v-if="isCampaignTitleRequire">The field is required.</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Target Audience</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="3">
                        <Multiselect v-model="form.target_audience" :options="targetAudience"
                          style="width: 300px; display: inline-flex;" :canClear="false" />
                      </td>
                      <td colspan="3">
                        <material-button class="mx-2 btn-background btn-sm"
                          @click="customizeAudienceList()">
                          Customize Audience List
                        </material-button>
                      </td>
                    </tr>
                    <tr v-if="isTargetAudienceRequire">
                      <td class="align-middle" colspan="2">
                      </td>
                      <td colspan="3" class="pt-0">
                          <span class="is-invalid">The field is required.</span>
                      </td>
                      <td colspan="3">
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">Campain Type</div>
                      </td>
                      <td colspan="6" class="d-flex">
                        <MaterialRadio v-model="form.campaign_type" @change="changeType($event)" class="ps-0 pe-4 pt-2"
                          id="newsletter" value="1">
                          Newsletter
                        </MaterialRadio>
                        <MaterialRadio v-model="form.campaign_type" @change="changeType($event)" class="ps-0 pt-2"
                          id="offer" value="2">
                          Offer
                        </MaterialRadio>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td colspan="8">
                        <material-checkbox :checked="form.is_email ? true : false" value="1" id="is_email"
                          v-model="form.is_email">
                          <template v-slot:label> Email </template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Email Subject</div>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-input v-model="form.email_subject" id="email_subject" name="email_subject"
                          placeholder="Enter email subject" />
                        <span class="is-invalid" v-if="isEmailSubjectRequire">The field is required.</span>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Email Message</div>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-textarea v-model="form.email_message" />
                        <span class="is-invalid" v-if="isEmailMessageRequire">The field is required.</span>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Image for Email Masthead</div>
                        </div>
                      </td>
                      <td colspan="6" class="d-flex">
                        <div class="align-items-center">
                          <material-input style="display: none" type="file" id="image"
                            @change="handleFileUpload($event)" accept="image/*" />
                          <material-button @click="uploadFile()" class="btn-background btn-sm capitalize">
                            Upload Icon
                          </material-button>
                          <div v-if="isImageRequire" class="is-invalid">
                            {{ messageImageError }}
                          </div>
                          <div class="pt-2 text-size-13"> Uploaded image minimum 100 x 100.</div>
                        </div>
                        <div id="preview" class="ps-2" style="position: relative">
                          <img v-if="urlImagePreview || form.icon"
                            :src="urlImagePreview ? urlImagePreview : form.icon" />
                          <i class="fa fa-times icon-close-sidenav-main danger" v-if="urlImagePreview || form.icon"
                            style="position: absolute; top: -8px; right: -15px; color: #f44335; font-size: 20px;"
                            aria-hidden="true" @click="clearImage()"></i>
                        </div>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td colspan="8">
                        <material-checkbox :checked="form.is_sms ? true : false" value="1" id="is_sms"
                          v-model="form.is_sms">
                          <template v-slot:label> SMS </template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Sms Message</div>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-textarea v-model="form.sms_message" />
                        <span class="is-invalid" v-if="isSmsMessageRequire">The field is required.</span>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="ps-0 label-form">Sent Date Time</div>
                      </td>
                      <td colspan="6" class="d-flex">
                        <Datepicker v-model="form.send_date" :enableTimePicker="false" class="date-time pe-2" :format="format"/>
                        <Datepicker v-model="form.send_time" timePicker class="date-time" />
                      </td>
                      <span class="is-invalid" v-if="isSendDateTimeRequire">{{ textRequiredSendDateTimeCustom }}</span>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td class="align-middle" colspan="2" rowspan="2">
                        <div class="ps-0 label-form">Rewards</div>
                      </td>
                      <td class="p-0 pt-2" colspan="2">
                        <MaterialRadio v-model="form.reward_type" id="reward_type_1" value="1"
                          @change="changeRewardType($event)" class="align-items-center">
                          Voucher
                        </MaterialRadio>
                      </td>
                      <td colspan="4" class="align-items-end p-0">
                        <div class="d-flex">
                          <Multiselect v-model="form.voucher_id" class="align-items-end" :options="vouchers"
                            :canClear="false" :disabled="disableVoucherDropdown" />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td class="p-0" colspan="2">
                        <MaterialRadio v-model="form.reward_type" id="reward_type_2" value="2"
                          @change="changeRewardType($event)" class="align-items-center mt-2">
                          Discount Code
                        </MaterialRadio>
                      </td>
                      <td colspan="4" class="align-items-end p-0">
                        <div class="d-flex align-items-center">
                          <Multiselect v-model="form.discount_id" class="align-items-end mt-2" :options="discounts"
                            :canClear="false" :disabled="!disableVoucherDropdown" />
                        </div>
                        <span class="is-invalid" v-if="isRewardTypeRequire">{{ textRequiredRewardType }}</span>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td class="align-middle pt-4 ps-0 pb-0" rowspan="4" colspan="2">
                        <div class="ps-0 label-form">Condition</div>
                      </td>
                      <td class="pt-4 ps-0 pb-0" colspan="">
                        <MaterialRadio v-model="form.condition_type" id="condition_type_1" value="1"
                          @change="changeConditionType($event)" class="align-items-center pt-2">
                          Minimum Spend Amount
                        </MaterialRadio>
                      </td>
                      <td colspan="7" class="align-items-end pt-4 ps-0 pb-0">
                        <div class="d-flex">
                          <div class="pe-1 d-flex align-items-center">$</div>
                          <material-input v-model="form.minimum_spend_amount" type="number" id="minimum_spend_amount"
                            :disabled="disableMinimumSpendAmountInput" name="minimum_spend_amount"
                            class="align-items-end" placeholder="Enter minimum spend amount" />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td colspan="" class="p-0">
                        <MaterialRadio v-model="form.condition_type" id="condition_type_2" value="2"
                          @change="changeConditionType($event)" class="align-items-center pt-2">
                          Minimum number of items purchased
                        </MaterialRadio>
                      </td>
                      <td colspan="7" class="align-items-end p-0">
                        <div class="d-flex align-items-center">
                          <material-input v-model="form.minimum_number_of_item_purchased" type="number"
                            :disabled="disableMinimumNumberOfItemPurchasedInput" id="minimum_number_of_item_purchased"
                            name="minimum_number_of_item_purchased" class="align-items-end mt-2"
                            placeholder="Enter minimum number of items purchased" style="margin-left: 13px;" />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td colspan="" class="p-0">
                        <MaterialRadio v-model="form.condition_type" id="condition_type_3" type="number" value="3"
                          @change="changeConditionType($event)" class="align-items-center pt-2">
                          Number of stores visit
                        </MaterialRadio>
                      </td>
                      <td colspan="7" class="align-items-end p-0">
                        <div class="d-flex align-items-center">
                          <material-input v-model="form.number_of_stores_visit" type="number"
                            :disabled="disableNumberOfStoresVisitInput" id="number_of_stores_visit"
                            name="number_of_stores_visit" class="align-items-end mt-2"
                            placeholder="Enter number of stores visit" style="margin-left: 13px;" />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td colspan="" class="p-0">
                        <MaterialRadio v-model="form.condition_type" id="condition_type_4" value="4"
                          @change="changeConditionType($event)" class="align-items-center pt-2">
                          None
                        </MaterialRadio>
                      </td>
                    </tr>
                    <tr v-if="isOffer && isConditionRequire" class="background-color-custom">
                      <td colspan="2" class="pt-0">
                      </td>
                      <td colspan="5" class="pt-0">
                        <span class="is-invalid ps-3"> {{ textRequiredConditionCustom }}</span>
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Campaign Start Date</div>
                        </div>
                      </td>
                      <td colspan="6" class="d-flex">
                        <Datepicker v-model="form.start_date" :enableTimePicker="false" class="date-time pe-2" :format="format"/>
                        <Datepicker v-model="form.start_time" timePicker class="date-time" />
                      </td>
                    </tr>
                    <tr v-if="isOffer" class="background-color-custom">
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Campaign End Date</div>
                        </div>
                      </td>
                      <td colspan="6" class="d-flex">
                        <Datepicker v-model="form.end_date" :enableTimePicker="false" class="date-time pe-2" :format="format"/>
                        <Datepicker v-model="form.end_time" timePicker class="date-time" />
                      </td>
                    </tr>
                    <tr v-if="isOffer && isDateRequire" class="background-color-custom">
                      <td colspan="2">
                      </td>
                      <td colspan="6" class="d-flex">
                        <span class="is-invalid">{{ textRequiredDateCustom }}</span>
                      </td>
                    </tr>
                    <tr class="background-color-custom">
                      <td colspan="8">
                        <div class="d-flex justify-content-end">
                          <material-button class="mx-2 btn-background btn-sm" @click="previewEmail()">
                            Preview Email
                          </material-button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <div class="d-flex justify-content-end">
                          <material-button class="mx-2 btn-background btn-sm"
                            @click="backCampaignList()">
                            Cancel
                          </material-button>
                          <material-button class="mx-2 btn-background btn-sm" @click="saveCampaign(0)">
                            Save Draft
                          </material-button>
                          <material-button class="mx-2 btn-background btn-sm" @click="saveCampaign(1)">
                            Publish
                          </material-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCustomizeAudienceList :getTargetAudience="getTargetAudience" />
      <ModalPreviewMail :mailSubject="form.email_subject" :mailMessage="form.email_message" :image="urlImagePreview ? urlImagePreview : form.icon"/>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
          :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
          :close-handler="closeSnackbar" />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ModalCustomizeAudienceList from "@/components/ModalCustomizeAudienceList.vue";
import ModalPreviewMail from "@/components/ModalPreviewMail.vue";
import SettingCampaignService from "../../../services/settingCampaign.service.js";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
import $ from "jquery";
export default {
  name: "DiscountDetail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    MaterialRadio,
    Multiselect,
    Datepicker,
    MaterialTextarea,
    MaterialCheckbox,
    ModalCustomizeAudienceList,
    ModalPreviewMail
  },
  data() {
    return {
      form: {
        campaign_id_text: '',
        id: null,
        campaign_tile: '',
        target_audience: 0,
        campaign_type: 1,
        is_email: 1,
        email_subject: '',
        email_message: '',
        icon: null,
        is_sms: 1,
        sms_message: '',
        send_time: null,
        send_date: null,
        send_date_time: null,
        reward_type: 1,
        voucher_id: 0,
        discount_id: 0,
        condition_type: 4,
        minimum_spend_amount: 0,
        minimum_number_of_item_purchased: 0,
        number_of_stores_visit: 0,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        status: 0,
      },
      isOffer: false,
      targetAudience: [],
      vouchers: [],
      discounts: [],
      loaderActive: false,
      isCampaignTitleRequire: false,
      isTargetAudienceRequire: false,
      isEmailSubjectRequire: false,
      isEmailMessageRequire: false,
      isSmsMessageRequire: false,
      isSendDateTimeRequire: true,
      textRequiredSendDateTimeCustom: "",
      isRewardTypeRequire: false,
      textRequiredRewardType: "",
      isConditionRequire: false,
      textRequiredConditionCustom: "",
      isDateRequire: false,
      textRequiredDateCustom: "",
      urlImagePreview: null,
      isImageTooSmall: false,
      isImageRequire: false,
      isErrorUploadImage: false,
      messageImageError: "",
      isLoadDoneData: false,
      snackbar: null,
      merchantCode: "",
      disableVoucherDropdown: false,
      disableMinimumSpendAmountInput: false,
      disableMinimumNumberOfItemPurchasedInput: false,
      disableNumberOfStoresVisitInput: false,
    };
  },
  created() {
    this.targetAudience = []
    this.branches = []
    this.getCampaignDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.form.icon = null;
        this.urlImagePreview = null;
        if (!["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(file.type)) {
          this.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.form.icon = file;
          this.urlImagePreview = URL.createObjectURL(this.form.icon);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.form.icon = null;
        this.urlImagePreview = null;
        this.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
    },

    clearImage() {
      this.form.icon = null;
      this.urlImagePreview = null;
      this.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    customizeAudienceList() {
      $(".btn-customize-audience-list-modal").click()
    },

    getTargetAudience() {
      this.isLoadDoneData = false;
      this.showLoader();
      SettingCampaignService.getDataTargetAudience().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.targetAudience = response.data.data
            this.isLoadDoneData = true;
            this.snackbar = "success"
            this.message = "Create group audience custom success"
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    previewEmail() {
      $(".btn-preview-mail-modal").click()
    },

    changeRewardType($event) {
      let rewardType = $event.target.value
      if (rewardType == 1) {
        this.disableVoucherDropdown = false
      } else {
        this.disableVoucherDropdown = true
      }
    },

    changeConditionType($event) {
      let conditionType = $event.target.value
      if (conditionType == 1) {
        this.disableMinimumSpendAmountInput = false
        this.disableMinimumNumberOfItemPurchasedInput = true
        this.disableNumberOfStoresVisitInput = true
      } else if (conditionType == 2) {
        this.disableMinimumSpendAmountInput = true
        this.disableMinimumNumberOfItemPurchasedInput = false
        this.disableNumberOfStoresVisitInput = true
      } else if (conditionType == 3) {
        this.disableMinimumSpendAmountInput = true
        this.disableMinimumNumberOfItemPurchasedInput = true
        this.disableNumberOfStoresVisitInput = false
      } else {
        this.disableMinimumSpendAmountInput = true
        this.disableMinimumNumberOfItemPurchasedInput = true
        this.disableNumberOfStoresVisitInput = true
      }
    },

    changeType($event) {
      let typeCampaign = $event.target.value
      if (typeCampaign == 1) {
        this.isOffer = false
      } else {
        this.isOffer = true
      }
    },
    getCampaignDetail() {
      var campaignId = this.$route.params.id ?? 0;
      this.isLoadDoneData = false;
      this.showLoader();
      SettingCampaignService.getDataCampaignDetail(campaignId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.form = response.data.data.campaign
            this.targetAudience = response.data.data.targetAudience
            this.vouchers = response.data.data.vouchers
            this.discounts = response.data.data.discounts
            if (this.form.campaign_type == 1) {
              this.isOffer = false
            } else {
              this.isOffer = true
            }
            if (this.form.reward_type == 1) {
              this.disableVoucherDropdown = false
            } else {
              this.disableVoucherDropdown = true
            }
            if (this.form.condition_type == 1) {
              this.disableMinimumSpendAmountInput = false
              this.disableMinimumNumberOfItemPurchasedInput = true
              this.disableNumberOfStoresVisitInput = true
            } else if (this.form.condition_type == 2) {
              this.disableMinimumSpendAmountInput = true
              this.disableMinimumNumberOfItemPurchasedInput = false
              this.disableNumberOfStoresVisitInput = true
            } else if (this.form.condition_type == 3) {
              this.disableMinimumSpendAmountInput = true
              this.disableMinimumNumberOfItemPurchasedInput = true
              this.disableNumberOfStoresVisitInput = false
            } else {
              this.disableMinimumSpendAmountInput = true
              this.disableMinimumNumberOfItemPurchasedInput = true
              this.disableNumberOfStoresVisitInput = true
            }
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveCampaign(saveType = 0) {
      this.form.send_date_time = this.dateTimeFormat(
        this.form.send_date,
        this.form.send_time
      );
      this.form.is_email = this.form.is_email == true ? 1 : 0
      this.form.is_sms = this.form.is_sms == true ? 1 : 0
      if (this.isImageTooSmall) {
        this.isErrorUploadImage = true;
        this.isImageRequire = true;
      } else {
        this.isImageRequire = false;
      }

      if (saveType == 0) {
        this.form.status = 0
      } else {
        this.form.status = 1
      }
      if (this.processValidate() && !this.isErrorUploadImage) {
        this.showLoader();
        if(this.form.reward_type == 1) {
          this.form.discount_id = 0
        } else {
          this.form.voucher_id = 0
        }
        this.form.start_date = this.dateTimeFormat(this.form.start_date, this.form.start_time);
        this.form.end_date = this.dateTimeFormat(this.form.end_date, this.form.end_time);
        SettingCampaignService.addOrUpdateCampaign(this.form).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Campaign Success";
              this.form = response.data.data;
              this.form.is_email = parseInt(this.form.is_email)
              this.form.is_sms = parseInt(this.form.is_sms)
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },
    dateTimeFormat(date, time) {
      if (date == null) {
        return null;
      } else {
        if (time == null) {
          return moment(date).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(date).format("YYYY-MM-DD") +
            " " +
            moment(time).format("H:m")
          );
        }
      }
    },
    backCampaignList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/campaigns" + "?page=" + page);
      } else {
        this.$router.push("/campaigns");
      }
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.form.campaign_tile == "" || this.form.campaign_tile == null) {
        this.isCampaignTitleRequire = true;
        isValid = false;
      }
      if (this.form.target_audience == "" || this.form.target_audience == null) {
        this.isTargetAudienceRequire = true;
        isValid = false;
      }
      if (this.form.campaign_type == 1) {
        if (!this.form.is_email && !this.form.is_sms) {
          this.snackbar = "danger";
          this.message = "Please checked send email or sms or both!";
          this.setTimeOutNotification();
          isValid = false;
        }
      }
      if (this.form.is_email && (this.form.email_subject == "" || this.form.email_subject == null)) {
        this.isEmailSubjectRequire = true;
        isValid = false;
      }
      if (this.form.is_email && (this.form.email_message == "" || this.form.email_message == null)) {
        this.isEmailMessageRequire = true;
        isValid = false;
      }
      if (this.form.is_sms && (this.form.sms_message == "" || this.form.sms_message == null)) {
        this.isSmsMessageRequire = true;
        isValid = false;
      }

      if ((this.form.is_email || this.form.is_sms) && (this.form.send_date_time == "" || this.form.send_date_time == null)) {
        this.isSendDateTimeRequire = true;
        this.textRequiredSendDateTimeCustom = "You must choose a date and time to send"
        isValid = false;
      }
      if (this.form.campaign_type == 2 && this.form.reward_type == 1 && (this.form.voucher_id == 0 || this.form.voucher_id == null)) {
        this.isRewardTypeRequire = true;
        this.textRequiredRewardType = "Please select voucher!"
        isValid = false;
      }
      else if (this.form.campaign_type == 2 && this.form.reward_type == 2 && (this.form.discount_id == 0 || this.form.discount_id == null)) {
        this.isRewardTypeRequire = true;
        this.textRequiredRewardType = "Please select discount!"
        isValid = false;
      }
      if (this.form.campaign_type == 2 && this.form.condition_type == 1 && (this.form.minimum_spend_amount == null || this.form.minimum_spend_amount == '')) {
        this.isConditionRequire = true;
        this.textRequiredConditionCustom = "Please enter value for minimum spend amount field!"
        isValid = false;
      } else if (this.form.campaign_type == 2 && this.form.condition_type == 2 && (this.form.minimum_number_of_item_purchased == null || this.form.minimum_number_of_item_purchased == '')) {
        this.isConditionRequire = true;
        this.textRequiredConditionCustom = "Please enter value for minimum number of item purchased field!"
        isValid = false;
      } else if (this.form.campaign_type == 2 && this.form.condition_type == 3 && (this.form.number_of_stores_visit == null || this.form.number_of_stores_visit == '')) {
        this.isConditionRequire = true;
        this.textRequiredConditionCustom = "Please enter value for number of stores visit field!"
        isValid = false;
      }

      if (this.form.campaign_type == 2 && (this.form.start_date == null || this.form.start_date == "" || this.form.end_date == null || this.form.end_date == "")) {
        this.isDateRequire = true;
        this.textRequiredDateCustom = "Start date and end date is required.";
        isValid = false;
      } else if (this.form.campaign_type == 2 && this.form.start_date && this.form.end_date) {
        const dateFrom = this.dateTimeFormat(
          this.form.start_date, this.form.start_time
        );
        const dateTo = this.dateTimeFormat(
          this.form.end_date, this.form.end_time
        );
        if (dateFrom != null && dateTo != null && moment(dateTo).isSameOrBefore(dateFrom)) {
          this.isDateRequire = true;
          this.textRequiredDateCustom = "The end date must be a date after start date.";
          isValid = false;
        }
      }
      return isValid;
    },

    resetRequired() {
      this.isCampaignTitleRequire = false;
      this.isTargetAudienceRequire = false;
      this.isEmailSubjectRequire = false;
      this.isEmailMessageRequire = false;
      this.isSmsMessageRequire = false;
      this.isSendDateTimeRequire = false;
      this.isRewardTypeRequire = false;
      this.isConditionRequire = false
      this.isDateRequire = false;
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    uploadFile() {
      $("#image").click();
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

div.div-custom {
  padding-top: 10px;
}

/* .background-color-custom {
  background-color: #EEEEEE;
} */
#preview img {
  min-width: 150px;
  max-height: 60px;
}
</style>