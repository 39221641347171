<template>
  <div>
    <div class="row my-2 d-flex">
      <div class="col-lg-6 col-md-6 col-12 title-page">
        STRIPE READER SETTING
      </div>
      <div class="d-flex align-items-center w-100 mt-3">
        <h3 class="title-page mt-1 ps-3" style="width: 200px">
          Physical Branch
        </h3>
        <div class="d-flex s-menuset align-items-center w-100">
          <Multiselect
            v-model="physicalBranchId"
            :options="physicalBranches"
            placeholder="Select physical branch"
            :canClear="false"
            :canDeselect="false"
            @change="selectPhysicalBranch"
          ></Multiselect>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions mt-3">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="row mt-3">
              <div class="col-12 d-flex justify-content-between">
                <h3 class="title-page">READER LIST</h3>
                <material-button
                  v-if="physicalBranchId"
                  @click="addReader()"
                  class="btn mb-0 btn-success btn-md btn-background"
                >
                  ADD READER
                </material-button>
              </div>
              <div class="col-12 mt-3">
                <div class="card-custom">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder">
                            Reader ID
                          </th>
                          <th class="text-center font-weight-bolder">
                            Virtual Branch
                          </th>
                          <th class="text-center font-weight-bolder">Name</th>
                          <th class="text-center font-weight-bolder">
                            IP Address
                          </th>
                          <th class="text-center font-weight-bolder">Status</th>
                          <th class="text-center font-weight-bolder">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="readers.length">
                        <tr v-for="(reader, index) in readers" :key="index">
                          <td class="text-center text-xss w-5">
                            {{ reader.reader_id }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{
                              reader.virtual_branch
                                ? reader.virtual_branch.branch_name
                                : ""
                            }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ reader.name }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ reader.ip_address }}
                          </td>
                          <td
                            class="text-center text-xss w-5"
                            :class="
                              reader.status == 'online'
                                ? 'text-success'
                                : 'text-danger'
                            "
                          >
                            {{ reader.status }}
                          </td>
                          <td class="text-center text-xss w-5">
                            <!-- <a
                              class="btn btn-link text-info px-2 mb-0"
                              @click="changeStatus(reader.id)"
                            >
                              {{
                                reader.status == "online" ? "Disable" : "Enable"
                              }}
                            </a> -->
                            <a
                              @click="addReader(reader.id)"
                              class="btn btn-link text-dark px-2 mb-0"
                            >
                              <i
                                class="fas fa-pencil-alt text-dark me-2"
                                aria-hidden="true"
                              ></i
                              >Edit
                            </a>
                            <a
                              @click="deleteReader(reader.id)"
                              class="btn btn-link text-danger text-gradient px-3 mb-0"
                            >
                              <i
                                class="far fa-trash-alt me-2"
                                aria-hidden="true"
                              ></i
                              >Delete
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="6" class="text-center">
                            No reader available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
      <AddReader
        :keyReload="keyReload"
        :physicalBranchId="physicalBranchId"
        :idSelected="readerIdSelected"
        @reload-data="reloadData"
      ></AddReader>
      <ModalConfirmDelete
        message="Are you sure you want to delete?"
        :process-yes="handleDeleteReader"
      />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import "@vuepic/vue-datepicker/dist/main.css";
import ReaderService from "../../services/reader.service";
import MaterialButton from "@/components/MaterialButton.vue";
import AddReader from "@/components/AddReader.vue";
import $ from "jquery";
import Multiselect from "@vueform/multiselect";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";

export default {
  name: "StripeReader",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    AddReader,
    Multiselect,
    ModalConfirmDelete,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      physicalBranches: [],
      keyReload: "key-reload",
      physicalBranchId: 0,
      readers: [],
      readerIdSelected: 0,
    };
  },
  async created() {
    this.getDataFilter();
  },
  methods: {
    addReader(readerId = 0) {
      this.readerIdSelected = readerId;
      this.keyReload = Math.random();
      $("#show-modal-add-reader").click();
    },

    selectPhysicalBranch(physicalBranchId) {
      this.keyReload = Math.random();
      this.physicalBranchId = physicalBranchId;
      this.getList(physicalBranchId);
    },

    deleteReader(readerId) {
      this.readerIdSelected = readerId;
      $(".btn-confirm-modal-delete").click();
    },

    handleDeleteReader() {
      this.showLoader();
      ReaderService.delete(this.readerIdSelected).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Delete Reader Success";
            this.setTimeOutNotification();
            $(".btn-close-modal").click();
            this.reloadData();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    changeStatus(readerId) {
      this.showLoader();
      ReaderService.changeStatus(readerId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Change status Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getList(physicalBranchId) {
      this.showLoader();
      var dataForm = {
        physical_branch_id: physicalBranchId,
      };

      ReaderService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.readers = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getDataFilter() {
      this.showLoader();
      ReaderService.getDataFilter({
        physical_branches: 1,
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.physicalBranches = response.data.data.physical_branches;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadData() {
      this.readerIdSelected = 0;
      this.getList(this.physicalBranchId);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}

.text-success {
  color: #28a745 !important;
}
</style>
